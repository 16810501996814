<template>
    <div class="register animate__animated animate__fadeInDown">
        <a-form>
        <a-form-item>
            <span class="label" slot="label">{{$t('register.label.account')}}</span>
            <!-- <a-input size="large" v-model="account" /> -->
            <a-input-search :placeholder="$t('register.placeholders.account')" ref="account" v-model="account" size="large" @search="sendMsg">
                <!-- <div class="color-gray otp-prefix" slot="prefix">90</div> -->
                <a-button class="otp-btn" slot="enterButton">
                    {{otpTimeleft>0?otpTimeleft:$t('register.otp')}}
                </a-button>
            </a-input-search>
        </a-form-item>
        <a-form-item>
            <span class="label" slot="label">{{$t('register.label.verifyCode')}}</span>
            <a-input size="large" :placeholder="$t('register.placeholders.otpCode')" ref="otpCode" v-model="otpCode" />
        </a-form-item>
        <a-form-item>
            <span class="label" slot="label">{{$t('register.label.password')}}</span>
            <a-input-password size="large" :placeholder="$t('register.placeholders.password')" ref="password" autocomplete="off"  v-model="password" />
        </a-form-item>
        <!-- <a-form-item>
            <span class="label" slot="label">{{$t('register.label.cpassword')}}</span>
            <a-input-password size="large" ref="cpassword" v-model="cpassword" />
        </a-form-item> -->
        
        <a-form-item>
            <span class="label" slot="label">{{$t('register.label.referer')}}</span>
            <a-input size="large" v-model="referer" :placeholder="$t('register.placeholders.referer')" :disabled="disableRefererIpt" />
        </a-form-item>
        <div class="btn-primary mg30" @click="submit">
            {{$t('common.register_btn')}}
        </div>
        <div class="color-gray">
            {{$t('register.already_registered')}} 
            <span @click="goLogin" class="color-white">{{$t('common.login_btn')}}</span>
        </div>
        </a-form>
      </div>
</template>
<script>

export default {
    name: 'Register',
    data(){
        return {
            referer: this.$route.params.i,
            account: '',
            password: '',
            cpassword: '',
            otpCode: '',
            disableRefererIpt: true,
            loading: false,
            otpIntval: {},
            otpTimeleft: 0
        }
    },
    components:{
    },
    mounted(){
        this.$store.commit('setShowNav', false);
        this.$store.commit('setShowLogo', true);
        // console.log(this.referer)
        if (!this.referer){
            this.disableRefererIpt = false
        }
        this.$refs.account.focus();
        
    },
    destroyed(){
        clearInterval(this.otpIntval);
    },
    methods:{
        submit(){
            this.loading = true;
            let _this = this;
            if(!this.account || !this.referer || !this.otpCode || !this.password){
                this.$message.error(this.$t('register.tips.iptError'));
                return;
            }
            // if(this.password != this.cpassword){
            //     this.$message.error(this.$t('register.tips.diff_password'));
            //     return;
            // }
            let data = {
                phone: this.account,
                code: this.otpCode,
                referer: this.referer,
                password: this.password
            };
            this.$http.call(this.$http.api.signup,{data:data}).then(function(res){
                let resp = res.data;
                if(resp.code=='200'){
                    _this.$store.commit('setToken', resp.data.token);
                    // _this.$store.commit('setUserinfo', resp.data.user);
                    _this.$router.push({name:'home'});
                }else{
                    _this.$message.error(resp.data);
                }
            },function(res){
                console.log(res.message);
                _this.$message.error(res.message);

            }).then(()=>{
                _this.loading = false;
            });
        },
        goLogin(){
            this.$router.push('/login');
        },
        sendMsg(){
            if(this.otpTimeleft>0){
                return;
            }
            if(!this.account){
                this.$message.error(this.$t('register.tips.iptError'));
                return;
            }
            let data = {
                phone: this.account
            };
            let _this = this;
            this.loading = true;
            this.$http.call(this.$http.api.sendmsg,{data:data}).then(function(res){
                let resp = res.data;
                console.log(resp)
                if(resp.code=='200'){
                    _this.$message.success(resp.data.message);
                    _this.otpTimeleft = resp.data.timeleft;
                    _this.otpIntval = setInterval(()=>{
                        _this.otpTimeleft --;
                        if(_this.otpTimeleft <= 0){
                            clearInterval(_this.otpIntval);
                        }
                    },1000);
                }else{
                    _this.$message.error(resp.data.message);
                }
            },function(res){
                console.log(res.message);
                _this.$message.error(res.message);

            }).then(()=>{
                _this.loading = false;
            });
        }
    }
}
</script>
<style lang="less">
.register{
    width: 100%;
    padding: 2rem;
    .account-ipt{
        text-align: right;
    }
    .otp-btn{
        background: @primary-color;
        border: 1px solid @primary-color;
        color: @white;
    }
}
</style>
